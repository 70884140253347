<template>
  <div class="col-md-4 mb-3">
    <div
      v-if="user"
      class="bg-white rounded shadow-sm sticky_sidebar overflow-hidden"
    >
      <a href="profile" class="">
        <div class="d-flex align-items-center p-3">
          <div class="left mr-3">
            <img
              alt="#"
              :src="
                user.userData.avatar != 'default.png'
                  ? $store.state.baseURL + '/' + user.userData.avatar
                  : 'img/user1.jpg'
              "
              class="rounded-circle"
            />
          </div>
          <div class="right">
            <h6 class="mb-1 font-weight-bold">
              {{ user.user.user_name }}
              <i class="feather-check-circle text-success"></i>
            </h6>
            <p class="text-muted m-0 small">{{ user.user.email }}</p>
          </div>
        </div>
      </a>
      <!-- profile-details -->
      <div class="bg-white profile-details">
        <a
          v-on:click="$emit('showAdd')"
          data-toggle="modal"
          data-target="#AddAddressModal"
          class="
            btn
            d-flex
            w-100
            align-items-center
            border-top border-bottom
            p-3
          "
        >
          <div class="left mr-3">
            <h6 class="font-weight-bold mb-1 text-left text-dark">Address</h6>
            <p class="small text-muted m-0">Add or remove a delivery address</p>
          </div>
          <div class="right ml-auto">
            <h6 class="font-weight-bold m-0">
              <i class="feather-chevron-right"></i>
            </h6>
          </div>
        </a>
        <a
          href="terms"
          class="d-flex w-100 align-items-center border-bottom px-3 py-4"
        >
          <div class="left mr-3">
            <h6 class="font-weight-bold m-0 text-dark">
              <i
                class="
                  feather-info
                  bg-success
                  text-white
                  p-2
                  rounded-circle
                  mr-2
                "
              ></i>
              Term of use
            </h6>
          </div>
          <div class="right ml-auto">
            <h6 class="font-weight-bold m-0">
              <i class="feather-chevron-right"></i>
            </h6>
          </div>
        </a>
        <a href="privacy" class="d-flex w-100 align-items-center px-3 py-4">
          <div class="left mr-3">
            <h6 class="font-weight-bold m-0 text-dark">
              <i
                class="
                  feather-lock
                  bg-warning
                  text-white
                  p-2
                  rounded-circle
                  mr-2
                "
              ></i>
              Privacy policy
            </h6>
          </div>
          <div class="right ml-auto">
            <h6 class="font-weight-bold m-0">
              <i class="feather-chevron-right"></i>
            </h6>
          </div>
        </a>
      </div>
    </div>
  </div>
  
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      user: null,
    };
  },
  computed: {
    ...mapState(["curUser"]),
  },

  mounted() {
    this.user = JSON.parse(this.curUser);
  },
};
</script>