<template>
  <div v-if="user" class="osahan-profile">
    <!-- profile -->
    <div class="container position-relative">
      <div class="py-5 osahan-profile row">
<user-sidebar/>
        <div class="col-md-8 mb-3">
          <div class="rounded shadow-sm">
            <div
              class="osahan-cart-item-profile bg-white rounded shadow-sm p-4"
            >
              <div class="flex-column">
                <h6 class="font-weight-bold">Tell us about yourself</h6>
                <p class="text-muted">
                  Whether you have questions or you would just like to say
                  hello, contact us.
                </p>
                <form ref="myForm">
                  <!-- <div class="form-group">
                    <label
                      for="exampleFormControlInput1"
                      class="small font-weight-bold"
                      >Your Name</label
                    >
                    <input
                      required
                      ref="name"
                      type="text"
                      class="form-control"
                      :value="yourName"
                      id="exampleFormControlInput1"
                      placeholder="Enter Your Name"
                    />
                  </div> -->
                  <div class="form-group">
                    <label
                      for="exampleFormControlInput2"
                      class="small font-weight-bold"
                      >Email Address</label
                    >
                    <input
                      readonly
                      type="email"
                      class="form-control"
                      :value="email"
                      id="exampleFormControlInput2"
                    />
                  </div>
                  <!-- <div class="form-group">
                    <label
                      for="exampleFormControlInput3"
                      class="small font-weight-bold"
                      >Phone Number</label
                    >
                    <input
                      required
                      ref="phone"
                      type="number"
                      class="form-control"
                      :value="phone"
                      id="exampleFormControlInput3"
                      placeholder="Enter yor phone number"
                    />
                  </div> -->
                  <div class="form-group">
                    <label
                      for="exampleFormControlTextarea1"
                      class="small font-weight-bold"
                      >HOW CAN WE HELP YOU?</label
                    >
                    <textarea
                      ref="msg"
                      :value="msg"
                      required
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder="Hi there, I would like to ..."
                      rows="3"
                    ></textarea>
                  </div>
                  <a
                    v-on:click="sendMessage"
                    class="btn btn-primary btn-block"
                    href="#"
                    >SUBMIT</a
                  >
                </form>
                <!-- Map -->
                <div v-show="false" class="mapouter pt-3">
                  <div class="gmap_canvas">
                    <iframe
                      width="100%"
                      height="100%"
                      id="gmap_canvas"
                      src="https://maps.google.com/maps?q=dugri%20ludhiana&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-address-modal  v-on:addressAdded="$router.push('success_save')" :addModalShow="addModalShow" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import addAddressModal from "../components/addAddressModal.vue";
import UserSidebar from '../components/userSidebar.vue';
export default {
  components: { addAddressModal, UserSidebar },
  data() {
    return {
      user: null,
      email: "",
      msg: "",
      addModalShow: false,
    };
  },
  methods: {
    sendMessage() {
      var BreakException = {};
      try {
        this.$refs.myForm.forEach((element) => {
          if (
            element.tagName.toLowerCase() == "input" ||
            element.tagName.toLowerCase() == "textarea"
          ) {
            if (!element.validity.valid) {
              element.focus();
              throw BreakException;
            }
          }
        });
        fetch(
          this.$store.state.baseURL +
            "/api/contact_us?email=" +
            this.email +
            "&message=" +
            this.msg,
          {
            method: "POST",
          }
        )
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              console.log(
                "Server returned " +
                  response.status +
                  " : " +
                  response.statusText
              );
            }
          })
          .then((response) => {
            this.result = response.data;
            this.$router.push("success_contact");
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (error == BreakException) console.log("break");
      }
    },
    showAdd() {
      if (this.addModalShow) this.addModalShow = false;
      else this.addModalShow = true;
    },
  },
  computed: {
    ...mapState(["curUser"]),
  },
  watch: {
    user(val) {
      this.email = val.user.email;
    },
  },
  mounted() {
    if (JSON.stringify(this.curUser) != "{}") {
      this.user = JSON.parse(this.curUser);
    } else {
      this.user = {
        user: {
          id: 0,
          user_name: "",
          email: "",
          phone: "",
          auth: "",
          branches: null,
        },
        userData: {
          user_id: 0,
          lookup_id: 0,
          name: "",
          avatar: "default.png",
          gender: "",
          birth_day: "0000-00-00",
          points: "",
          coins: 0,
          device_type: "",
          user_token: "",
          device_active_token: null,
          created_by: 0,
          updated_by: null,
          deleted_by: null,
          suspend: 0,
          suspend_by: null,
          referrer_code: null,
          confirmation_code: null,
          email_confirmed: 0,
          mobile_confirmed: 0,
          created_at: "",
          updated_at: "",
          deleted_at: null,
        },
        password: true,
        favourites: [],
        address: [],
        token: "",
      };
    }
  },
};
</script>
