<template>
  <div
    v-if="addressToAdd"
    v-show="modalAddShow"
    class="modal fade"
    id="AddAddressModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="EditAddressModalLabel"
    :aria-hidden="!modalAddShow"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Delivery Address</h5>
          <button
            id="AddNewAddressButton"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form ref="myForm" class="">
            <div class="form-row">
              <div class="col-md-12 form-group">
                <label class="form-label">Delivery city</label>
                <div class="input-group">
                  <select
                    required
                    placeholder="Delivery City"
                    type="text"
                    v-model="city"
                    class="custom-select"
                  >
                    <option
                      class="custom-control"
                      :value="city.id"
                      v-for="city in cities"
                      :key="city.id"
                      
                    >
                      {{ city.name_en }}
                    </option>
                  </select>
                  <div v-show="false" class="input-group-append">
                    <button type="button" class="btn btn-outline-secondary">
                      <i class="feather-map-pin"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-12 form-group">
                <label class="form-label">Delivery Area</label>
                <div class="input-group">
                  <select
                    required
                    placeholder="Delivery Area"
                    type="text"
                    v-model="addressToAdd.area"
                    class="custom-select"
                  >
                    <option
                      class="custom-control"
                      :value="area.id"
                      v-for="area in areas"
                      :key="area.id"
                    >
                      {{ area.area_name_en }}
                    </option>
                  </select>
                  <div v-show="false" class="input-group-append">
                    <button type="button" class="btn btn-outline-secondary">
                      <i class="feather-map-pin"></i>
                    </button>
                  </div>
                </div>
              </div>
              cities
              <div class="col-md-12 form-group">
                <div class="col-md-12">
                  <label class="col-form-label-sm m-1" for="addStreet"
                    >Street:
                  </label>
                  <input
                    required
                    v-model="addressToAdd.street"
                    class="form-control-sm border"
                    type="text"
                    id="addStreet"
                  />
                  <label class="form-label-sm m-1" for="addbuilding"
                    >Buliding:
                  </label>
                  <input
                    required
                    v-model="addressToAdd.building"
                    class="form-control-sm border col-sm-2"
                    type="number"
                    min="1"
                    id="addbuilding"
                  />
                  <br />
                  <label class="col-form-label-sm m-1" for="addfloor"
                    >floor:
                  </label>
                  <input
                    required
                    v-model="addressToAdd.floor"
                    class="form-control-sm small border col-sm-2"
                    type="text"
                    id="addfloor"
                  />
                  <label class="form-label-sm m-1" for="addapt">apt.: </label>
                  <input
                    required
                    v-model="addressToAdd.apt"
                    class="form-control-sm border col-sm-2"
                    type="number"
                    min="1"
                    id="addapt"
                  />
                </div>
              </div>
              <div class="col-md-12 form-group">
                <label class="form-label">Delivery Instructions</label
                ><input
                  v-model="addressToAdd.addtional"
                  placeholder="Enter additional information"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="mb-0 col-md-12 form-group">
                <label class="form-label">Nickname</label>
                <div
                  class="btn-group btn-group-toggle w-100"
                  data-toggle="buttons"
                >
                  <label class="btn btn-outline-secondary active">
                    <input
                      type="radio"
                      v-on:click="
                        addressToAdd.address_name = 'Home';
                        showOther = false;
                      "
                      name="options"
                      id="option12"
                      checked="true"
                    />
                    Home
                  </label>
                  <label class="btn btn-outline-secondary">
                    <input
                      type="radio"
                      v-on:click="
                        addressToAdd.address_name = 'Work';
                        showOther = false;
                      "
                      name="options"
                      id="option22"
                      checked="false"
                    />
                    Work
                  </label>
                  <label class="btn btn-outline-secondary">
                    <input
                      type="radio"
                      v-on:click="
                        addressToAdd.address_name = '';
                        showOther = true;
                      "
                      name="options"
                      id="option32"
                      checked="false"
                    />
                    Other
                  </label>
                </div>
                <div>
                  <input
                    required
                    v-show="showOther"
                    type="text"
                    v-model="addressToAdd.address_name"
                    placeholder="Enter Nickname"
                    class="col-12 border form-control-sm"
                    name="otherName"
                    id="otherName"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer p-0 border-0">
          <div class="col-6 m-0 p-0">
            <button
              v-on:click="showAdd"
              type="button"
              id="btnCloseAdd"
              class="btn border-top btn-lg btn-block"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
          <div class="col-6 m-0 p-0">
            <button
              v-on:click="AddNewAddess"
              type="button"
              class="btn btn-primary btn-lg btn-block"
              data-dismiss="modal"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
class addressObj {
  constructor() {
    this.addtional = null;
    this.area = null;
    this.street = null;
    this.building = null;
    this.floor = null;
    this.apt = null;
    this.address_name = "Home";
    this.lat = 0;
    this.lng = 0;
  }
}
export default {
  props: ["addModalShow"],
  data() {
    return {
      addressToAdd: null,
      areas: null,
      cities:null,
      city:null,
      showOther: false,
      modalAddShow: this.addModalShow,
    };
  },
  computed: {
    ...mapState(["baseURL", "selCity", "curUser"]),
  },

  methods: {
    AddNewAddess() {
      var BreakException = {};
      try {
        this.$refs.myForm.forEach((element) => {
          if (
            element.tagName.toLowerCase() == "input" ||
            element.tagName.toLowerCase() == "textarea" ||
            element.tagName.toLowerCase() == "select"
          ) {
            if (!element.validity.valid) {
              element.focus();
              throw BreakException;
            }
          }
        });

        let a = this.addressToAdd;
        let url =
          this.baseURL +
          `/api/profile/address/add?api_token=${
            JSON.parse(this.curUser).token
          }` +
          `&additional=${a.addtional}&area=${a.area}&street=${a.street}&building=${a.building}` +
          `&floor=${a.floor}&apt=${a.apt}&name=${a.address_name}` +
          `&lat=${a.lat}&lng=${a.lng}`;
        let request = new Request(url, { method: "POST" });
        fetch(request)
          .then((r) => {
            if (r.ok) {
              return r.json();
            }
          })
          .then((r) => {
            if (r.response) {
              this.addressToAdd = null;
              this.$emit("addressAdded");
            } else {
              if (r.message) console.log(r.message);
              else if (r.messages) console.log(r.messages);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (error == BreakException) console.log("break");
        else console.log(">> "+error)
      }
    },
    getAreas(cityId) {
      let url = this.baseURL + "/api/areas?city=" + cityId;
      let request = new Request(url, { method: "GET" });
      fetch(request)
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
        })
        .then((r) => {
          if (r.response) {
            this.areas = JSON.parse(JSON.stringify(r)).data.areas;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
        getCities() {
      fetch(this.$store.state.baseURL + "/api/cities", {
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          this.result = response.data;
          this.cities = JSON.parse(JSON.stringify(this.result)).cities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showAdd() {
      if (this.modalAddShow) {
        this.modalAddShow = false;
      } else {
        this.modalAddShow = true;
      }
    },
  },
  mounted() {
    this.addressToAdd = new addressObj();
    // get areas
    this.getCities()
    
  },
  watch:{
    cities(val){
      if(val){
      this.getAreas(val[0].id);
      }
    },
    city(val){
      this.getAreas(val);
    }
  }
};
</script>